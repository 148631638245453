<template>
  <div>
    <div v-if="!loader">
      <Main class="footer">
        <div>
          <a-row :gutter="25">
            <a-col
              v-for="order in orders"
              :key="order.id"
              :lg="8"
              :xl="7"
              :md="13"
              :sm="12"
            >
              <a-col style="padding-top: 30px">
                <CardStyleWrapper class="width">
                  <sdCards :headless="true" :border="true" class="left">
                    <a-row>
                      <a-col
                        :lg="16"
                        :xl="24"
                        :md="10"
                        :xs="24"
                        style="padding-top: 10px; color: #6d6868; font-weight: bold"
                      >
                        <h4>
                          <b
                            >{{ order.customer.first_name }}
                            {{ order.customer.last_name }}</b
                          >
                          <br />
                          {{ order.customer.email }} <br />
                          {{ order.customer.gender }} <br />
                          {{ order.customer.phone }}
                        </h4>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col
                        :lg="16"
                        :xl="24"
                        :md="12"
                        :xs="24"
                        style="padding-top: 5px; color: #6d6868"
                      >
                        TransId {{ order.transaction_id }}
                      </a-col>
                      <a-col
                        :lg="16"
                        :xl="24"
                        :md="12"
                        :xs="24"
                        style="padding-top: 5px; color: #6d6868"
                      >
                        Frequency {{ order.frequency != "90" ? "Monthly" : "Quarterly " }}
                      </a-col>
                      <a-col
                        :lg="16"
                        :xl="24"
                        :md="12"
                        :xs="24"
                        style="padding-top: 5px; color: #6d6868"
                      >
                        {{ order.casestatus }}
                      </a-col>
                      <a-col
                        :lg="16"
                        :xl="24"
                        :md="24"
                        :xs="24"
                        style="padding-top: 5px; color: #6d6868"
                      >
                        Paid Amount <b>${{ order.total_amount }} </b>
                      </a-col>
                      <a-col
                        :lg="16"
                        :xl="24"
                        :md="24"
                        :xs="24"
                        style="padding-top: 5px; color: #6d6868"
                      >
                        Request Date: {{ order.created_at.split(" ")[0] }}
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :lg="16" :xl="24" :md="24" :xs="24" style="padding-top: 5px">
                        <sdButton
                          size="small"
                          type="primary"
                          class="receipt"
                          style="background-color: #0db3b5; margin-left: 10px"
                          @click="
                            () => {
                              showModalReceipt(order);
                              changeType('primary');
                            }
                          "
                        >
                          Receipt
                        </sdButton>
                        <sdButton
                          v-if="order.casestatus === 'Shipped'"
                          size="small"
                          type="primary"
                          class="button"
                          style="background-color: #0db3b5; margin-left: 30px"
                          @click="showModalOrder(order)"
                        >
                          Track Order
                        </sdButton>
                        <!-- <button
                          type="button"
                          v-if="order.casestatus === 'Shipped' && loaderTracking"
                          class="spinner"
                          disabled
                        >
                        <a-spin>
                          <a-icon  slot="indicator" type="loading" style="font-size: 35px;" spin />
                          <a-spin :indicator="indicator" />
                          </a-spin>
                        </button> -->
                      </a-col>
                    </a-row>
                  </sdCards>
                </CardStyleWrapper>
              </a-col>

              <!-- </sdCards> -->
            </a-col>
          </a-row>
        </div>
        <sdModal
          :type="modalType"
          :onOk="handleOk"
          :visible="visible"
          :onCancel="handleCancel"
        >
          <h1
            style="
              color: white !important;
              text-align: left;
              padding-left: 2%;
              margin-right: -2%;
              margin-top: -3%;
            "
            class="modal-header"
          >
            Track Your Order
          </h1>
          <a-row> </a-row>
          <a-row>
            <a-col :xl="24">
              <a-form :form="form" @submit="trackingmore()">
                <a-form-item name="trackingNumber" label="Tracking Number">
                  <a-input
                    type="text"
                    style="text-transform: capitalize; color: black"
                    v-model:value="trackingNumber"
                    disabled
                    value=""
                    required
                  />
                </a-form-item>
                <a-form-item
                  name="couriercode"
                  style="margin-top: -5%"
                  label="Courier Name"
                >
                  <a-input
                    type="text"
                    style="text-transform: capitalize; color: black"
                    v-model:value="couriercode"
                    disabled
                    value=""
                    required
                  />
                </a-form-item>
                <sdButton style="background-color: #1baba8" size="large" type="primary">
                  <div>Track Order</div>
                </sdButton>
              </a-form>
            </a-col>
          </a-row>
        </sdModal>
        <sdModal
          :type="modalType3"
          :onOk="handleOk"
          :visible="shippingModal"
          :onCancel="shippingCancel"
        >
          <h1
            class="modal-header"
            style="
              color: white !important;
              text-align: left;
              padding-left: 2%;
              margin-right: -2%;
              margin-top: -3%;
            "
          >
            Tracking Detail
          </h1>
          <div class="modal-body">
            <h3 class="modal-content" style="color: black !important">
              Tracking Number: {{ trackNumber }}
            </h3>
            <h3
              class="modal-content"
              style="color: black !important; text-transform: capitalize"
            >
              Courier Number: {{ courier_code }}
            </h3>
            <h3
              class="modal-content"
              style="color: black !important; text-transform: capitalize"
            >
              Lastest Status: {{ latest_status }}
            </h3>
          </div>
          <tr class="mb-4 mt-10" v-for="trackinfo in apiData">
            <div class="row" style="margin-top: 1.5rem !important; display: flex">
              <!-- <div class="mr-3 col-2"> -->
              <a-col :lg="4" :xl="6" :md="3" :xs="6">
                <!-- <svg width="30px" style="color: #0db3b5;padding-top: 4%" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512">! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.<path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg> -->
                <img
                  :src="require('./../../static/img/check.png')"
                  style="width: 30px !important"
                  alt="Navigate"
                />
              </a-col>
              <!-- </div> -->
              <!-- <div class="col-8 mt-6"> -->
              <a-col :lg="6" :xl="12" :md="6" :xs="12">
                <h3
                  class="text-primary font-bold inline"
                  style="text-transform: capitalize !important; color: #0db3b5"
                >
                  {{ trackinfo.track }}
                </h3>
                <h4 class="text-primary font-bold" style="color: #0db3b5">
                  <img
                    :src="require('./../../static/img/route.png')"
                    style="width: 20px !important"
                    alt="Navigate"
                  />
                  {{ trackinfo.trackstatus }}
                </h4>
                <span class="text-primary text-base">
                  <img
                    :src="require('./../../static/img/calendar.png')"
                    style="width: 20px !important"
                    alt="Calendar"
                  />
                  {{ trackinfo.checkpointDate }}
                </span>
              </a-col>
              <!-- </div> -->
            </div>
          </tr>
        </sdModal>
        <sdModal
          :type="modalType2"
          :onOk="handleOk2"
          :visible="visible2"
          :onCancel="handleCancel2"
        >
          <!-- <h1 class="modal-header">Your Order Receipt</h1> -->
          <a-row>
            <a-col style="text-align: center" :xl="24">
              <img :src="require('./../../static/img/logo-full.png')" alt="" />
            </a-col>
          </a-row>
          <a-row>
            <a-col :xl="24">
              <h3 class="modal-header-inner" style="text-align: center">
                {{ receipt.treatment.name }}
              </h3>
            </a-col>
          </a-row>
          <a-row>
            <a-col :xl="24">
              <!-- <p class="modal-content">
                Treatment Description Here Treatment Description Here Treatment Description Here Treatment Description
                Here
              </p> -->
            </a-col>
          </a-row>
          <a-row>
            <a-col :xl="6"></a-col>
            <a-col :xl="12">
              <a-card class="card-receipt">
                <p v-if="receipt.frequency === '90'">Quarterly  Plan</p>
                <p v-if="receipt.frequency === '30'">Monthly Plan</p>
                <p v-if="receipt.frequency === '1'">Monthly Plan</p>
                <p>
                  <span class="card-price">${{ receipt.total_amount }}</span>
                </p>
              </a-card>
            </a-col>
          </a-row>
          <a-row style="padding-top: 30px">
            <a-col :xl="24">
              <p class="modal-content">
                You selected the ({{
                  receipt.frequency === "90" ? "QUARTERLY" : "MONTHLY"
                }}
                | PLAN), You will receive a ({{
                  receipt.frequency === "90" ? "QUARTERLY" : "MONTHLY"
                }}) Supply of your treatment medication in each shipment. To modify, delay
                or cancel your plan please head to the treatments section of our customer
                portal. If you have any questions regarding your treatment please feel
                free to message your healthcare professional through the messages section.
                Any questions regarding billing or shipment please use our live chat
                located in the bottom right of our website or via phone at +1 (855)
                785-1636 alternatively you can email us at support@getbetterdoctor.com
              </p>
            </a-col>
          </a-row>
          <a-row style="padding-top: 30px">
            <a-col :xl="12">
              <h3 class="modal-content-receipt-left">Transaction Id</h3>
            </a-col>
            <a-col :xl="12">
              <p class="modal-content-receipt-right">{{ receipt.transaction_id }}</p>
            </a-col>
          </a-row>
          <a-row>
            <a-divider />
          </a-row>

          <a-row>
            <a-col :xl="12">
              <h3 class="modal-content-receipt-left">Coupon Amount</h3>
            </a-col>
            <a-col :xl="12">
              <p class="modal-content-receipt-right">$ {{ receipt.coupon_amount }}</p>
            </a-col>
          </a-row>
          <a-row>
            <a-divider />
          </a-row>
          <a-row>
            <a-col :xl="12">
              <h3 class="modal-content-receipt-left">Total Amount After Discount</h3>
            </a-col>
            <a-col :xl="12">
              <p class="modal-content-receipt-right">$ {{ receipt.total_amount }}</p>
            </a-col>
          </a-row>
          <a-row>
            <a-divider />
          </a-row>
        </sdModal>
      </Main>
    </div>
    <div v-else>
      <Main class="footer">
        <div class="loaderstyle">
          <div class="loader"></div>
        </div>
      </Main>
    </div>
  </div>
</template>

<script>
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Main } from "../styled";
import { CardStyleWrapper } from "./../uiElements/ui-elements-styled";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { Modal } from "ant-design-vue";
import axios from "axios";
import { Notification } from "ant-design-vue";

export default {
  name: "AllTreatment",
  components: {
    Main,
    CardStyleWrapper,
  },
  data() {
    return {
      orders: [],
      loader: true,
      loaderTracking: false,
      visible: false,
      visible2: false,
      shippingModal: false,
      confirmLoading: false,
      modalType: "primary",
      modalType2: "primary",
      modalType3: "primary",
      colorVisible: false,
      trackMessage: "",
      trackId: "",
      trackDate: "",
      trackDetails: [],
      receipt: null,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      trackingNumber: "",
      couriercode: "",
      latest_status: "",
      courier_code: "",
    };
  },
  methods: {
    async showModalOrder(order) {
      console.log(order);
      console.log("showModalOrder");
      this.loaderTracking = true;
      this.visible = true;
      this.trackingNumber = order.tracking_number;
      this.couriercode = order.shipment_provider;
    },
    async trackingmore() {
      console.log(JSON.stringify(trackingNumber));
      console.log(JSON.stringify(couriercode));
      let vm = this;
      // await axios.post('https://127.0.0.1:8000/api/track-more', { tracking_number: this.trackingNumber, couriercode: this.couriercode })
      await axios
        .post("get-shipmentDetail.php", {
          tracking_number: this.trackingNumber,
          couriercode: this.couriercode,
        })
        .then((res) => {
          if (res.data.length > 0) {
            console.log(res.data);
            vm.apiData = res.data;
            vm.latest_status = res.data[0].track;
            vm.trackNumber = res.data[0].trackNumber;
            vm.courier_code = res.data[0].courier_code;
            //Vue.prototype.$toasts.success('Request Processed')
            this.visible = false;
            console.log(this.shippingModal);
            this.shippingModal = true;
            //alert(this.shippingModal)
          } else {
            alert("Invalid Tracking Number or couriercode");
          }
        });
    },
    showModalReceipt(order) {
      this.receipt = order;
      this.visible2 = true;
    },
    handleCancel() {
      this.visible = false;
      this.colorVisible = false;
    },
    handleCancel2() {
      this.visible2 = false;
      this.colorVisible = false;
    },
    shippingCancel() {
      this.shippingModal = false;
      this.colorVisible = false;
    },
    changeType(type) {
      this.modalType = type;
    },
    async getorders() {
      await axios
        .get("get-orders.php")
        //.get('https://127.0.0.1:8000/api/customers')
        //.get('https://api.getbetterdoctor.com/api/customers')
        .then((res) => {
          let response = res.data;
          // console.log(response.medicines)
          this.orders = response.orders;

          // formState.name=  response.billing.name
          //           formState.xr_dosage= response.billing.xr_dosage
          console.log("orders");

          console.log(response);
          //console.log(response)
          if (response.status === false) {
          }

          if (response.status === true) {
            this.loader = false;
            this.orders = response.orders;

            // formState.name=  response.billing.name
            //           formState.xr_dosage= response.billing.xr_dosage
            console.log("orders");

            // console.log(orders);
          }
        })
        .catch((err) => {
          console.log("err");

          console.log(err);
        });
    },
  },
  mounted() {
    this.getorders();
  },
  // async setup() {

  //   let orders = []
  //     await  axios.get('https://api.getbetterdoctor.com/api/customers/2').then( res => {
  //     let  response = res.data
  //       // console.log(response.medicines)
  //  orders = response.orders

  // // formState.name=  response.billing.name
  // //           formState.xr_dosage= response.billing.xr_dosage
  //       console.log('orders')

  //       console.log(response)
  //       //console.log(response)
  //     if(response.status === false){

  //     }

  //     if(response.status === true){
  //       orders = response.orders

  // // formState.name=  response.billing.name
  // //           formState.xr_dosage= response.billing.xr_dosage
  //       console.log('orders')

  //       console.log(orders)
  // }

  //      }).catch( err => {
  //       console.log('err')

  //       console.log(err)
  //     })

  // return {

  // orders
  // };

  // },

  // methods: {

  // },
  // mounted() {

  // },
};
</script>

<style>
.spinner {
  background-color: transparent !important;
  border: transparent !important;
  margin-left: 3% !important;
  margin-top: -1% !important;
}

.card-receipt {
  background-color: #0db0b2;
  text-align: center;
  color: #ffffff;
}

.card-price {
  font-weight: bold;
  font-size: 22px;
}

.modal-content-receipt-left {
  text-align: left;
  color: #6d6868;
  padding-top: 5px;
}

.modal-content-receipt-right {
  text-align: right;
  color: #0db0b2;
  padding-top: 5px;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #0db0b2;
  font-weight: bolder;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #0db0b2;
  font-weight: bolder;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #0db0b2 !important;
}

.ant-steps-icon {
  color: #0db0b2 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #ffffff;
  border-color: #0db0b2 !important;
}

.modal-header {
  text-align: center;
  font-weight: bold;
  background-color: rgb(14, 184, 183);
  color: #ffffff;
}

.modal-header-inner {
  text-align: left;
  font-weight: bold;
  color: #6d6868;
}

.modal-content {
  color: grey;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #0db0b2 !important;
}

.center {
  margin: auto;
  text-align: center;
  /* width: 50%; */
  /* border: 3px solid green; */
  padding: 10px;
}

.spinlogo {
  padding-top: 400px;
  padding-left: 700px;
}

.footer {
  min-height: calc(100vh - 114px);
}

@media (min-width: 300px) and (max-width: 320px) {
  /* .button {
    margin-left: 0px !important;
    margin-top: 10px;
  } */
  .receipt {
    margin-right: 2%;
    margin-left: 0px !important;
    margin-top: 10px;
  }

  /* .ant-card-body {
    border: 1px solid #f0f0f0;
    height: 250px;
    width: 210%;
  } */
}

/* @media (min-width: 321px) and (max-width: 768px) {
  .ant-card-bordered {
    border: 1px solid #f0f0f0;
    height: 200px;
  }
} */
@media (min-width: 200px) and (max-width: 299px) {
  .ant-card-bordered {
    border: 1px solid #f0f0f0;
    height: 300px;
  }

  /* .button {
    margin-left: 0px !important;
    margin-top: 10px;
  } */
}

@media (min-width: 480px) and (max-width: 768px) {
  /* .button {
    margin-left: -6px !important;
    margin-top: 10px;
  } */
  .receipt {
    margin-right: 6%;
    margin-left: -9px !important;
    margin-top: 10px;
  }

  /* .ant-card-body {
    border: 1px solid #f0f0f0;
    height: 250px;
    width: 210%;
  } */
}

/* @media (min-width: 769px) and (max-width: 1023px) {
  .button {
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .receipt{
    margin-right: 2%;
    margin-left: 0px !important;
    margin-top: 10px;

  }
  .ant-card-body {
    border: 1px solid #f0f0f0;
    height: 250px;
    width: 210%;
  }
} */
/* @media (min-width: 1024px) and (max-width: 1200px) {
  .button {
    margin-left: -80px !important;
    margin-top: 50px;
  }
} */
@media (min-width: 1201px) and (max-width: 1700px) {
  /* .button {
    margin-left: -6px !important;
    margin-top: 10px;
  } */

  /* .ant-card-bordered {
    width: 184%;
  } */
  .receipt {
    margin-right: 4%;
    margin-left: -9px !important;
    margin-top: 10px;
  }

  /* .ant-card-body {
    border: 1px solid #f0f0f0;
    height: 220px;
    width: 100%;
  } */
}

.loader {
  border: 10px solid #0db0b2;
  border-radius: 50%;
  border-top: 4px solid white;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.loaderstyle {
  align-content: center;
  padding-top: 20%;
  padding-left: 40%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
